import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—April 2009" />
	<NewsHeader />
    <h2>April 2009</h2>



	<h3 className="blogdate">
	Thursday, April 30, 2009
	</h3>

	<h4 className="blogitemtitle">
	Photos and review of Paramount show
	</h4>
	   <div className="blogitembody">
	   <p>I've just uploaded a selection of photos from my concert at The Paramount
	on April 17, 2009, with Gerry Paul. Thanks to Ranya Kaiser for these
	photos and her permission to use them. You can find them at Facebook and
	Myspace, in addition to my website.</p>

	<p>
	Also, I got a nice review from the show, published at writing.com. I've
	placed this review, as well as one of my favorite quotes from it, at the
	Press section of my website.
	</p>
	<p>
	And now back to slowly, slowly producing my next CD...
	</p>
	<p>
	Ciao!
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 00:19 +1200</p>
	   </div>


	<h3 className="blogdate">
	Wednesday, April 15, 2009
	</h3>

	<h4 className="blogitemtitle">
	Gerry Paul to join Wellington show
	</h4>
	   <div className="blogitembody">
	   <p>A quick update regarding my Wellington show this Friday: Gerry Paul of
	Irish/Celtic band <a href="http://www.gradamusic.com/">Grada </a>will be
	joining me for several songs at the end of my set. He'll be bringing his
	banjo, guitar, hopefully some colorful pants, and he'll also be
	implementing my dears, Goldie and Ampie, on <i><span>Greyhound Through the
	Rockies</span></i>. A little extra flavour for you - hope to see you there!</p>

	<ul>
	<li>
	<span><b>Friday, April 17 @ 8:00 pm, @ The Paramount</b></span>, 25
	Courtenay Place, Wellington, New Zealand
	</li>
	</ul>
	<p>Ciao,</p><p>Jesse</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 19:28 +1200</p>
	   </div>


	<h3 className="blogdate">
	Tuesday, April 07, 2009
	</h3>

	<h4 className="blogitemtitle">
	Upcoming Wellington show
	</h4>
	   <div className="blogitembody">
	   <p>Ahoy, Wellington! Mark this date in your calendar:</p>

	<ul>
	<li>
	<span><b>Friday, April 17 @ 8:00 pm, @ The Paramount</b></span>, 25
	Courtenay Place, Wellington, New Zealand
	</li>
	</ul>
	<p>
	It's been a year since my last show in Wellington - wow, it seems like
	just yesterday! Here's the deal - it's me and my guitars. This is a
	free, one-hour showcase of some songs that I am preparing to record for
	a new CD. I'll love it (and probably you'll love it) if you attend this
	fabulous and (did I mention?) free event.
	</p>
	<p>
	Also, I am inviting (consider this an open invitation) any web writers
	or bloggers to attend, take in the show, and publish something about it.
	I am lacking online (and printed) praise and criticism, and I value the
	published words of web-savvy people like you and I. So please forward
	this invite to any such friends or acquaintances you have. Heck, send
	this invite to everyone!
	</p>
	<p>
	See you there,
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 22:31 +1200</p>
	   </div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
